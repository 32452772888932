import React from 'react'

const Help = () => {
  return (
    <div className='font-[Montserrat-Arabic] text-primary mt-16 border-2 rounded-xl p-4 mx-auto w-9/12 h-fit
                    text-right
                    bg-red-20'>... سيـم تحديثه لاحقا</div>
  )
}

export default Help