import React, { useEffect, useState, useContext } from "react";
import ServiceCard from "../../components/ServiceCard";
import { useLocation } from "react-router-dom";
import { axiosAPI, axiosAccount } from "../../axios";
import APIContext from "../../context/APIContext";

const ServicesList = () => {
    const { search } = useLocation();
    const id = new URLSearchParams(search).get("id");
    const [services, setServices] = useState([]);
    const [category, setCategory] = useState(null);
    const get_services_data = async () => {
        try {
            const { data } = await axiosAPI({
                url: `/categories/${id}`,
                method: "GET",
                header: {
                    "Content-Type": "application/json",
                },
            });
            setServices(data.categoryServices);
            setCategory(data.categoryData);
            console.log(data.categoryServices.length);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        get_services_data();
    }, [id]);

    return (
        <div className=" flex flex-col gap-14 my-24 justify-center">
            {/* SERVICES LIST */}
            <div className="text-center">
                <h1 className=" font-readex font-bold text-primary text-[25px]  sm:text-[50px] leading-[30.47px] sm:leading-[60.95px] ">
                    {category?.name}
                </h1>
            </div>
            <div
                className={
                    services
                        ? "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-5/6 mx-auto gap-y-24 gap-x-16 "
                        : " flex justify-center items-center text-right mb-14"
                }
            >
                {services.length ? (
                    services.map((service, index) => {
                        return <ServiceCard service={service} key={index} />;
                    })
                ) : (
                    <h1 className=" text-xl">...ليست هنالك أي نتيجـة</h1>
                )}
            </div>
        </div>
    );
};

export default ServicesList;
